#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: #bbb !important;
}

.login-legaltext {
  color: #ecf0f1;
  max-height: 350px;
  overflow: auto;
  max-height: 200px;
}

.login-background {
  width: 100%;
  margin-top: -20px;
  // background-image: url(./texture1.jpg);
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // background-size: cover;
  // background-attachment: fixed;
  // background-position: center center;
  height: 100vh;
  min-height: 1280px;
}

.bv360-container {
  width: 25%;
  min-width: 350px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgba(145, 72, 217, 0.3);
  // opacity: 0.5;
}

.container-login {
  // margin-top: 50px;
  top: 6em;
  left: 10em;
  position: absolute;
  .panel-body > p {
    padding: 2% 0;
  }
}

.rightLogo-login {
  top: 2em;
  right: 5em;
  position: absolute;
}

#okta-sign-in {
  font-family: montserrat, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  background-color: #f9f9f9;
  color: #777;
  position: relative;
  overflow: auto;
  border: none;
  height: auto;
  margin: 10px auto 8px;
  width: 100%;
  min-width: 300px;
}

.okta-form-infobox-error.infobox.infobox-error {
  color: red;
}

#okta-sign-in.auth-container .okta-sign-in-header,
#login-div {
  border: none;
}

#okta-sign-in.auth-container .button-primary {
  border-radius: 0;
  background: $brand-primary;
  border-color: #49176d !important;
}

#okta-sign-in .default-custom-button {
  border-color: #734694 !important;
}

#okta-sign-in .o-form .input-fix,
#okta-sign-in .o-form .textarea-fix {
  border-radius: 0;
}

#okta-sign-in.auth-container .button-primary:hover,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:visited,
.btn-disabled,
.link-button-disabled {
  color: #a7a9ac;
  background: $brand-primary !important;
}

#okta-sign-in .o-form-explain.o-form-input-error {
  color: #ecf0f1 !important;
}

// height restrictions*******************************//
@media only screen and (max-height: 870px) {
  .login-legaltext {
    max-height: 170px;
  }

  .container-login {
    top: 1em;
  }

  .login-background {
    min-height: 1000px;
  }
}
@media only screen and (max-height: 668px) {
  .login-legaltext {
    color: #ecf0f1;
    max-height: 100px;
    overflow: auto;
  }
}

// width restrictions*******************************//
@media only screen and (max-width: 768px) {
  .container-login {
    // margin-top: 50px;

    left: 0;
    right: 0;
    margin: auto;
  }

  .rightLogo-login {
    visibility: hidden;
  }
}
@media only screen and (max-width: 400px) {
  .bv360-container {
    min-width: 350px;
  }

  .container-login {
    top: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .okta-login-portal {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 321px) {
  #okta-sign-in.auth-container .auth-content {
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    border: none;
  }
}
