#okta-sign-in.auth-container .auth-footer .link.help.js-help {
  font-weight: bold;
  cursor: not-allowed;
  opacity: 0.9;
  text-decoration: none;
  pointer-events: none;
}

#okta-sign-in.auth-container .auth-footer .help-links.js-help-links {
  display: block !important;
}

#okta-sign-in .auth-divider-text {
  color: grey;
}

#okta-sign-in .default-custom-button {
  background-color: #734694 !important;
}

#okta-sign-in .default-custom-button:hover {
  color: #A7A9AC;
  background-color: #734694 ;
  box-shadow: none;
}
#okta-sign-in .default-custom-button:focus {
  box-shadow: 0 0 8px #51cbee;
}

#okta-sign-in .btn-customAuth {
  background-color: #734694;
  border: 1px solid #0f4397;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container.main-container {
    /* -- Main Background and Border Colors -- */
    background-color: rgba(145, 72, 217, 0.0);
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
    color: #ECF0F1;
  }
#okta-sign-in.auth-container.link {
    color: #ECF0F1;
}

#okta-sign-in.auth-container .link {
    /* -- Fonts and Text Colors: links -- */
    color:#ECF0F1;
  }
  
  #okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:active {
    /* -- Fonts and Text Colors: links -- */
    color: #ECF0F1;
  }

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  /* -- Fonts and Text Colors -- */
  font-weight: bold;
  color: #ECF0F1;
}

#okta-sign-in {
    font-family: montserrat,Arial,Helvetica,sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #f9f9f9;
    color: #777;
    position: relative;
    overflow: auto;
    border: none;
    height: auto;
    margin: 10px auto 8px;
    width: 100%;
    min-width: 300px;
}

#okta-sign-in.auth-container .button-primary {
    border-radius: 0;
    background: #49176D;
}

#okta-sign-in .o-form .input-fix, #okta-sign-in .o-form .textarea-fix {
    border-radius: 0;
}

#okta-sign-in.auth-container .button-primary:hover, #okta-sign-in.auth-container .button-primary:focus, #okta-sign-in.auth-container .button-primary:active, #okta-sign-in.auth-container .button-primary:focus, #okta-sign-in.auth-container .button-primary:visited, .btn-disabled, .link-button-disabled {
    color: #A7A9AC;
    background: #49176D !important;
}
#okta-sign-in .auth-header {
  padding: 0;
  height: 70px;
}
#okta-sign-in .auth-org-logo {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 70px;
}

@media only screen and (min-width: 700px) {
  .okta-login-portal {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 321px) {
    #okta-sign-in.auth-container .auth-content {
        max-width: 100%;
        padding: 0;
        margin: 0 auto;
        border: none;
    }
}

#okta-sign-in.auth-container .okta-sign-in-header, #login-div {
    border: none;
}

#okta-sign-in .focused-input, #okta-sign-in .link.help:focus {
  box-shadow: 0 0 8px #49176D;
}

#okta-sign-in .btn-customAuth:focus {
  box-shadow: 0 0 8px #734694;
}

#okta-sign-in.auth-container .okta-form-subtitle, #okta-sign-in.auth-container .okta-form-label {
  color: #ECF0F1;
}

.login-legaltext {
  color: #ECF0F1;
  background: #49176D;
  padding: 5px;
  max-height: 350px;
  overflow: auto;
  max-height: 200px;
}
