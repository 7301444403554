#content {
    height: 100%;
    min-height: 100%;
}

.spinner-wrapper {
    position: fixed;
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    top: 0;
}

.spinner {
    display: flex;
    margin: 0 auto;
    color: $brand-primary;
    font-size: 1rem;
}

.card-body > .row > div {
    margin-bottom: 0.7em;
}

.text--copyright {
    font-size: 0.9rem;
}

.ReactTable {
    font-size: 0.75rem;
}

.btn {
    font-size: 0.75rem;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    background: $brand-primary;
    border: $brand-primary;
}

.content--app {
    min-height: calc(100vh - 59px);
    // padding-top: 5em;
    padding-top: 70px;
    padding-bottom: 5em;
    @media(min-width: 1051px) {
        // padding-top: 7em;
        padding-top: 98px;
    }

    @media(min-width: 1200px) {
        // padding-top: 7em;
        padding-top: 98px;
    }
}

.grecaptcha-badge {
    display:none !important;
}

.panel-tooltip > .tooltip-inner {
    background-color: rgb(124, 181, 236);
  }

  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: rgb(124, 181, 236);
  }

  .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: rgb(124, 181, 236);
  }

  .confirm-requesting-user {
    justify-content: center;

    ul {
        list-style-type: none;
        font-size: x-large;
    }
  }
