/****************************************
	Layout
****************************************/
.cookie-Table {
  width: 30%;
  color: #fff;
}

.myBV360-Header-Logo {
  height: 70px;
}

.OfficeDropdown {
  color: black;
  font-size: 12pt;
}

.OfficeDropdown .Select-control {
  background-color: rgba(255, 0, 0, 0.12) !important;
  border: 0 !important;
}
.OfficeDropdown .Select-input {
  opacity: 0;
  // border: 0 !important;
}
.OfficeDropdown .Select-control .Select-value-label {
  color: white !important;
}

.fa.fa-user {
  width: 130px;
  height: 130px;
  font-size: 10em;
  text-align: Center;
}
/**===== TOP HEADER ==**/
#TopHead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #49176d;
  border-bottom: 1px solid #fff;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: 1001;
}

#TopHead .Logo {
  float: left;
  margin: 5px 0 5px 25px;
}
#TopHead .LogoRight {
  float: right;
  margin-right: 25px;
  margin-top: 20px;
}

/*= TopNav =*/
//#TopNav { float:left;}

@media All and (min-width: 992px) {
  #TopNavRight {
    // float:right;
    // padding-right: 1rem;
    position: absolute;
    right: 10px;
  }
}

@media All and (max-width: 991px) {
  .sign-out-icon {
    visibility: hidden;
  }
}

.mainmenu {
  background: none;
  border: 0 solid;
  margin: 23px 0 0 0;
  padding: 0;
  min-height: auto;
  width: auto;
}
.mainmenu a,
.navbar-default .navbar-nav > li > a,
.mainmenu ul li a,
.navbar-expand-lg .navbar-nav .nav-link {
  display: block !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  padding: 6px 2px;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  position: relative;
}

.dropdown-toggle::after {
  display: none;
}

.mainmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mainmenu ul li {
  margin: 0 0 0 30px;
  padding: 0;
  list-style: none;
}
.mainmenu .active a,
.mainmenu .active a:focus,
.mainmenu .active a:hover,
.mainmenu li a:hover,
.mainmenu li a:focus,
.navbar-default .navbar-nav > .show > a,
.navbar-default .navbar-nav > .show > a:focus,
.navbar-default .navbar-nav > .show > a:hover {
  color: #fff;
  background: none;
  outline: 0;
}

.mainmenu li > a > span,
.mainmenu .collapse ul > li:hover > a span {
  width: 0;
  height: 100%;
  position: absolute;
  display: block;
  border-bottom: 1px solid #fff;
  left: 50%;
  top: 0;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -ms-transition: ease !important;
}
.mainmenu li > a:hover > span,
.mainmenu .collapse ul > li:hover > a span {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  left: 0;
}
.mainmenu .collapse ul > li:hover > a {
  background: none;
}

.mainmenu .collapse ul li .Notification {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 14px;
  line-height: 20px;
  color: #00afec;
  font-weight: 700;
}

.mainmenu .collapse > ul > li:hover {
  position: relative;
}
.mainmenu .collapse > ul > li.linedown:hover::after {
  content: '';
  height: 23px;
  width: 1px;
  background: #fff;
  position: absolute;
  bottom: -23px;
  left: 50%;
}

/*= SubMenu */
.dropdown-menu {
  padding: 0;
  margin: 0;
  border: 0 solid transition !important;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mainmenu .collapse ul ul,
.mainmenu .collapse ul ul.dropdown-menu {
  background: none;
  padding-top: 23px;
}

.mainmenu ul ul.dropdown-menu li {
  margin: 0;
}
.mainmenu ul ul.dropdown-menu li a {
  background: rgba(73, 23, 97, 0.88);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
  padding: 15px;
  border-top: 1px solid #fff;
  white-space: nowrap;
}

.mainmenu ul ul.dropdown-menu .dropdown ul.dropdown-menu {
  padding-top: 0;
  border-left: 1px solid #fff;
}

// myBV360
.mainmenu ul li ul.dropdown-menu li.focus a,
.mainmenu ul li ul.dropdown-menu li.opened a,
.mainmenu ul li ul.dropdown-menu li:hover > a {
  background: #49176d;
}

// Services
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li.focus a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li.opened a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:hover > a {
  background: #b95915;
}

.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(2).focus a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(2).opened a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(2):hover > a {
  background: #00aeef;
}

.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(3).focus a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(3).opened a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(3):hover > a {
  background: #00aeef;
}

.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(4).focus a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(4).opened a,
.mainmenu .collapse ul li:nth-child(2) ul.dropdown-menu li:nth-child(4):hover > a {
  background: #00aeef;
}

// Product Information
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li.focus a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li.opened a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:hover > a {
  background: #b95915;
}

.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(2).focus a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(2).opened a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(2):hover > a {
  background: #00aeef;
}

.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(3).focus a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(3).opened a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(3):hover > a {
  background: #00aeef;
}

.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(4).focus a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(4).opened a,
.mainmenu .collapse ul li:nth-child(3) ul.dropdown-menu li:nth-child(4):hover > a {
  background: #00aeef;
}

// these aren't used right now//////
.mainmenu .collapse ul li:nth-child(4) ul.dropdown-menu li.focus a,
.mainmenu .collapse ul li:nth-child(4) ul.dropdown-menu li.opened a,
.mainmenu .collapse ul li:nth-child(4) ul.dropdown-menu li:hover > a {
  background: #00aeef;
}

.mainmenu .collapse ul li:nth-child(7) ul.dropdown-menu li.focus a,
.mainmenu .collapse ul li:nth-child(7) ul.dropdown-menu li.opened a,
.mainmenu .collapse ul li:nth-child(7) ul.dropdown-menu li:hover > a {
  background: #00b259;
}
//*******Exogen submenu*************************//

.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li.focus a,
.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li.opened a,
.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li:hover > a {
  background: #b95915;
}
.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li:nth-child(2).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li:nth-child(2).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.exogen-services li:nth-child(2):hover > a {
  background: #b95915;
}
//*******Durolane submenu*************************//

.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li.focus a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li.opened a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(2).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(2).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(2):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(3).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(3).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(3):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(4).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(4).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(4):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(5).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(5).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.durolane-services li:nth-child(5):hover > a {
  background: #00aeef;
}

//**********Gelsyn submenu**********************//

.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li.focus a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li.opened a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(2).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-servicesli:nth-child(2).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(2):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(3).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(3).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(3):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(4).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(4).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.gelsyn-services li:nth-child(4):hover > a {
  background: #00aeef;
}

//***********supartz submenu*********************//

.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li.focus a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li.opened a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(2).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(2).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(2):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(3).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(3).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(3):hover > a {
  background: #00aeef;
}
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(4).focus a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(4).opened a,
.mainmenu .collapse ul li ul.dropdown-menu.supartz-services li:nth-child(4):hover > a {
  background: #00aeef;
}

//***************************************************//

@media only screen and (min-width: 991px) {
  // dropdowns on the top header
  .mainmenu .collapse ul li:hover > ul {
    display: block;
  }
  .mainmenu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 150px;
    display: none;
  }

  .mainmenu .collapse ul ul li {
    position: relative;
  }
  .mainmenu .collapse ul ul li:hover > ul {
    display: block;
  }
  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none;
  }

  .mainmenu .collapse ul ul ul li {
    position: relative;
  }
  .mainmenu .collapse ul ul ul li:hover ul {
    display: block;
  }
  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1;
  }
}
@media only screen and (max-width: 991px) {
  // changes the padding on the dropdown menu items
  .navbar-nav .show .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 35px;
  }
  .navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 45px;
  }
}
@media All and (max-width: 1440px) {
  .mainmenu ul li {
    margin-left: 20px;
  }
}
@media All and (max-width: 1270px) {
  .mainmenu {
    padding-left: 0;
  }
  .mainmenu ul li {
    margin-left: 15px;
  }
  .mainmenu a,
  .navbar-default .navbar-nav > li > a,
  .mainmenu ul li a,
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 15px;
  }
  .mainmenu .collapse ul ul.dropdown-menu li a {
    font-size: 14px;
  }
}
@media All and (max-width: 1100px) {
  .mainmenu {
    margin-top: 14px;
  }
  .mainmenu .collapse ul ul,
  .mainmenu .collapse ul ul.dropdown-menu {
    padding-top: 12px;
  }
  .mainmenu .collapse > ul > li:hover::after {
    height: 12px;
    bottom: -12px;
  }
}
@media All and (max-width: 991px) {
  .navbar {
    padding: 0;
    width: 100%;
  }
  .navbar-toggler {
    padding: 0;
    font-size: 24px;
    line-height: 1;
    background-color: #b95915;
    border: none;
    border-radius: 0;
    color: #fff;
    position: absolute;
    top: -60px;
    right: 0;
    line-height: 60px;
    width: 60px;
  }
  .dropdown-toggle::after {
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    top: 18px;
    right: 5px;
  }
  .mainmenu .collapse ul ul.dropdown-menu li a {
    background: rgba(59, 56, 62, 0.7);
  }

  .mainmenu ul {
    background: rgba(73, 23, 97, 1);
    padding: 20px;
    margin-top: 1px;
  }
  .dropdown-toggle::after {
    display: block;
  }

  .mainmenu ul li {
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
  .mainmenu ul li:last-child {
    border-bottom: none;
  }

  .mainmenu a,
  .navbar-default .navbar-nav > li > a,
  .mainmenu ul li a,
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 5px;
  }

  .mainmenu li > a > span,
  .mainmenu .collapse ul > li:hover > a span {
    display: none;
  }

  .mainmenu .collapse ul li .Notification {
    top: 0;
    line-height: 42px;
    background: #00afec;
    width: 42px;
    text-align: center;
    color: #fff;
  }
  .mainmenu .collapse ul ul,
  .mainmenu .collapse ul ul.dropdown-menu {
    padding: 0;
  }
  .mainmenu .collapse > ul > li > ul::after,
  .mainmenu .collapse > ul > li:hover::after {
    display: none;
  }
  .mainmenu .collapse ul ul.dropdown-menu li {
    border-bottom: none;
  }
}

/**===== BODY CONTENTS ==**/
/* https://stackoverflow.com/questions/55573052/mix-blend-mode-background-with-child-content-unaffected */

.Background,
.HomeBackground {
  width: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.HomeBackground {
  height: 100vh;
}

.Background > .Inner {
  padding-top: 61px;
  padding-bottom: 59px;
}
.Background > .Inner > .row,
.Background > .Inner > .row > * > .row {
  margin: 0;
}

.Background > .Inner > .row > .col-lg-8 > .row > * {
  padding: 0;
  height: calc((100vh - 140px) / 2);
  position: relative;
}
.Background > .Inner > .row > * {
  padding: 0;
  height: calc(100vh - 140px);
  position: relative;
}

.Background > .Inner > .row > div:after,
.Background > .Inner > .row > .col-lg-8 > .row > div:after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.Background.LandingPage > .Inner > .row > * > .row > div:nth-child(1):after {
  background-color: #007278;
  mix-blend-mode: screen;
}
.Background.LandingPage > .Inner > .row > * > .row > div:nth-child(2):after {
  background-color: #b44f08;
  mix-blend-mode: screen;
}
.Background.LandingPage > .Inner > .row > * > .row > div:nth-child(3):after {
  background-color: #00aeef;
  mix-blend-mode: screen;
}
.Background.LandingPage > .Inner > .row > * > .row > div:nth-child(4):after {
  background-color: #49176d;
  mix-blend-mode: screen;
}
.Background.LandingPage > .Inner > .row > * > .row > div:nth-child(5):after {
  background-color: #00b259;
  mix-blend-mode: screen;
}

/*Team Colum*/
.Background.LandingPage > .Inner > .row > div:nth-child(2):after {
  background-color: #7c0040;
  mix-blend-mode: screen;
}

@supports not (mix-blend-mode: color) {
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(1):after {
    background-color: rgba(140, 65, 200, 0.5);
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(2):after {
    background-color: rgba(250, 115, 20, 0.7);
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(3):after {
    background-color: rgba(30, 195, 255, 0.8);
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(4):after {
    background-color: rgba(35, 215, 220, 0.5);
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(5):after {
    background-color: rgba(0, 178, 89, 0.7);
  }

  /*Team Colum*/
  .Background.LandingPage > .Inner > .row > div:nth-child(2):after {
    background-color: rgba(203, 10, 110, 0.6);
  }
}

_:-ms-fullscreen .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(1):after,
:root .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(1):after {
  background-color: rgba(35, 215, 220, 0.5);
}

_:-ms-fullscreen .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(2):after,
:root .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(2):after {
  background-color: rgba(250, 115, 20, 0.7);
}

_:-ms-fullscreen .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(3):after,
:root .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(3):after {
  background-color: rgba(30, 195, 255, 0.8);
}

_:-ms-fullscreen .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(4):after,
:root .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(4):after {
  background-color: rgba(140, 65, 200, 0.5);
}

_:-ms-fullscreen .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(5):after,
:root .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(5):after {
  background-color: rgba(0, 178, 89, 0.7);
}

/*Team Colum*/
_:-ms-fullscreen .Background.LandingPage > .Inner > .row > div:nth-child(2):after,
:root .Background.LandingPage > .Inner > .row > div:nth-child(2):after {
  background-color: rgba(203, 10, 110, 0.6);
}

.LandingPage .BoxContents {
  color: #fff;
  // color:red;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  position: relative;
  z-index: 1;
  padding: 25px;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.Background.LandingPage > .Inner > .row > div:nth-child(2) .BoxContents {
  border-right-color: transparent;
}

/*.Background.LandingPage>.Inner>.row>div:nth-child(3) .BoxContents,
	.Background.LandingPage>.Inner>.row>div:nth-child(6) .BoxContents { border-right:none;}*/

.LandingPage .BoxContents:hover {
  background: rgba(0, 0, 0, 0.35);
}

.LandingPage .BoxContents:hover .TileName {
  text-decoration: underline;
}

.LandingPage .BoxContents .Name {
  font-size: 22px;
  line-height: 28px;
  color: #fff;
  // color: red;
  margin-top: 25px;
}

.LandingPage .BoxContents .Name a {
  color: #fff;
}

.LandingPage .BoxContents .SubLink {
  margin-top: 25px;
}
.LandingPage .BoxContents .SubLink a {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  margin: 0 10px;
  padding: 4px 1px;
  text-transform: uppercase;
}
.LandingPage .BoxContents .SubLink a span {
  width: 0;
  height: 100%;
  position: absolute;
  display: block;
  border-bottom: 1px solid #fff;
  left: 50%;
  top: 0;

  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -ms-transition: ease !important;
}
.LandingPage .BoxContents .SubLink a:hover span {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  left: 0;
}

.OverflowContents {
  height: 100%;
  width: 100%;
  text-align: left;
  overflow-y: auto;
}
.BoxContents.Team .Name {
  margin-top: 15px;
  margin-bottom: 25px;
  text-align: left;
  width: 100%;
}

.MemberInfo {
  position: relative;
  margin-bottom: 10px;
}

.MemberInfo .Pic {
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
}
.MemberInfo .Pic img {
  width: 130px;
  height: 130px;
}

.MemberInfo .Detail {
  float: left;
  font-size: 17px;
  line-height: 24px;
}
.MemberInfo .Membername {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.MemberInfo .MemberProducts {
  font-size: 14px;
  font-style: italic
}
.MemberInfo .ShortInfo p {
  margin-bottom: 0;
  font-size: 15px;
}
.MemberInfo .ShortInfo i {
  font-size: 14px;
  color: #fff;
  margin-right: 5px;
}

.MemberInfo a {
  color: #fff;
  position: relative;
  text-decoration: none;
  display: inline-block;
}
.MemberInfo a span {
  width: 0;
  height: 100%;
  position: absolute;
  display: block;
  border-bottom: 1px solid #fff;
  left: 50%;
  top: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.MemberInfo a:hover span {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  left: 0;
}

/*===== FOOTER ==*/
#Footer {
  background: #76777b;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  z-index: 1;
}
#Footer a {
  color: #fff;
}

#Footer .Inner {
  padding: 10px 25px 5px 25px;
}

#Footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#Footer ul li {
  margin: 0 20px 0 0;
  padding: 2px;
  display: inline-block;
}
#Footer ul li a {
  font-weight: 700;
  letter-spacing: 0.04em;
}
#Footer ul li a:hover {
  color: #000;
}

#Footer .ChatBtn {
  position: absolute;
  top: -10px;
  right: 0;
}
#Footer .ChatBtn a {
  background: #b95915;
  border: 1px solid #fff;
  border-top: none;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  display: inline-block;
  padding: 10px 30px;
  -webkit-border-radius: 0 0 20px 20px;
  border-radius: 0 0 20px 20px;
}
#Footer .ChatBtn a:hover {
  background: #fff;
  color: #111;
}

.clear {
  clear: both;
}
.clear_left {
  clear: left;
}
.clear_right {
  clear: right;
}

/****************************************
	MEDIA QUERIES
****************************************/

/***** for 1366px or less ===========*/
@media All and (max-width: 1370px) {
  .MemberInfo .Pic {
    margin-right: 20px;
  }

  .fa.fa-user {
    width: 110px;
    height: 110px;
    font-size: 10em;
    text-align: Center;
  }

  .MemberInfo .Pic img {
    width: 110px;
    height: 110px;
  }

  #TopHead .LogoRight {
    margin-right: 15px;
    margin-top: 26px;
  }
  #TopHead .LogoRight img {
    height: 25px;
  }
}

/***** for 1280px or less ===========*/
@media All and (max-width: 1280px) {
  .MainWrapper,
  .parallax-box .PrlxInnerWrapp {
    margin: 0px 40px;
  }
  .parallax-box .MainWrapper {
    margin: 0;
  }
}

@media All and (max-width: 1199px) {
  #TopHead .Logo {
    margin-left: 20px;
  }
  #TopHead .ChatBtn {
    margin-right: 20px;
  }
}

/***** for 1024px or less ===========*/

@media All and (max-width: 1100px) {
  #TopHead .Logo {
    margin-left: 15px;
  }
  #TopHead .Logo img {
    height: 50px;
  }

  #TopHead .LogoRight {
    margin-right: 10px;
    margin-top: 20px;
  }
  #TopHead .LogoRight img {
    height: 22px;
  }

  .Background > .Inner > .row > .col-lg-8 > .row > * {
    height: calc((100vh - 120px) / 2);
  }
  .Background > .Inner > .row > * {
    height: calc(100vh - 120px);
  }
  .Background > .Inner {
    padding-top: 41px;
  }
}
@media All and (max-width: 1024px) {
}

/***** for 1000px or less ===========*/
@media All and (max-width: 991px) {
  /*#TopHead .ChatBtn { display:none;}*/

  #TopHead {
    height: 61px;
  }

  #TopHead .LogoRight {
    float: none;
    margin: 0;
    position: absolute;
    right: 70px;
    top: 19px;
  }
  #TopNav {
    float: none;
  }

  #Footer .ChatBtn {
    display: none;
  }

  #Footer {
    position: relative;
  }
  #Footer.HomePage {
    position: fixed;
  }

  .Background > .Inner > .row > * {
    height: auto;
  }
  .Background > .Inner {
    padding-bottom: 0;
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(2) .BoxContents,
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(4) .BoxContents {
    border-right-color: transparent;
  }
}

/***** for 768px or less ===========*/
@media All and (max-width: 768px) {
  .ParallaxWrapp > div,
  .parallax-box .MainWrapper {
    padding: 100px 0;
  }
}

@media All and (max-width: 767px) {
  #Footer ul li {
    margin-right: 10px;
  }
  .Background,
  .HomeBackground {
    background-position: center right;
  }

  .Background > .Inner > .row > .col-lg-8 > .row > * {
    height: auto;
  }
  .LandingPage .BoxContents {
    padding: 20px;
    min-height: 300px;
  }
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(1) .BoxContents,
  .Background.LandingPage > .Inner > .row > * > .row > div:nth-child(3) .BoxContents {
    border-right-color: transparent;
  }

  /*.Background>.Inner>.row>* { height:auto;}
	*/
  /*.Background.LandingPage>.Inner>.row>div:nth-child(1) .BoxContents,
	.Background.LandingPage>.Inner>.row>div:nth-child(3) .BoxContents,
	.Background.LandingPage>.Inner>.row>div:nth-child(4) .BoxContents,
	.Background.LandingPage>.Inner>.row>div:nth-child(5) .BoxContents { border-right:none;}*/
}

@media All and (max-width: 760px) {
  #Footer img {
    height: 30px;
  }
}

@media All and (max-height: 650px) {
  .Background > .Inner > .row > * {
    width: 100%;
    height: auto;
    // max-height:600px;
  }
  .LandingPage .BoxContents {
    padding: 20px;
    min-height: 300px;
  }
  .Background > .Inner > .row > .col-lg-8 > .row > * {
    width: 100%;
    height: auto;
  }
}

/***** for 480px or less ===========*/
@media All and (max-width: 480px) {
  .Background,
  .HomeBackground {
    background-position: center right -150px;
  }
}

/***** for 320px or less ===========*/
@media All and (max-width: 320px) {
}
