@use "sass:math";

/* stylelint-disable selector-max-id */
@mixin rgba($property, $color: $black, $opacity: 0.75, $mix: $white) {
  #{$property}: mix($color, $mix, $opacity * 100%);
  #{$property}: rgba($color, $opacity);
}

@mixin border-rgba($color: $black, $opacity: 0.75, $property-suffix: null, $mix: $white) {
  @if ($property-suffix == null) {
    border: 1px solid mix($color, $mix, $opacity * 100%);
    border: 1px solid rgba($color, $opacity);
  } @else {
    border-#{$property-suffix}: 1px solid mix($color, $mix, $opacity * 100%);
    border-#{$property-suffix}: 1px solid rgba($color, $opacity);
  }
}

@mixin light-button-template($bg-color) {
  @include button-template($bg-color, 1%, $dark-text-color, 22%, 25%);
  @include button-shadow(0.05);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin dark-button-template($bg-color) {
  @include button-template($bg-color, 3%, #fff, 15%, 17%);
  @include button-shadow(0.15);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin button-shadow($drop-shadow-opacity) {
  box-shadow: rgba(#000, $drop-shadow-opacity) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

@mixin button-template($bg-color, $gradient-factor, $text-color, $border-factor, $border-bottom-factor) {
  color: $text-color;
  background-color: $bg-color;
  background: -o-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* IE10+ */
  background: linear-gradient($bg-color, darken($bg-color, $gradient-factor));
  border-color: darken($bg-color, $border-factor);
  border-bottom-color: darken($bg-color, $border-bottom-factor);

  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    $hover-bg-color: lighten($bg-color, 3%);
    background-color: $hover-bg-color;
    background: -o-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* IE10+ */
    background-image: linear-gradient($hover-bg-color, darken($hover-bg-color, $gradient-factor));
    border-color: darken($bg-color, $border-factor * 1.3);
    border-bottom-color: darken($bg-color, $border-bottom-factor * 1.2);
    text-decoration: none;
    cursor: pointer;
  }
}


@mixin max-width-mq($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin max-height-mq($height) {
  @media only screen and (max-height: $height) {
    @content;
  }
}

@mixin max-width-text($width) {
  width: $width;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin mobile {
  @media only screen
  /* ----------- iPhone 4 and 4S ----------- */
  /* Portrait and Landscape */
    and (min-device-width: 320px)
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
  @media only screen
  /* ----------- iPhone 5 and 5S ----------- */
  /* Portrait and Landscape */
    and (min-device-width: 320px)
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
  @media only screen
  /* ----------- iPhone 6 ----------- */
  /* Portrait and Landscape */
    and (min-device-width: 375px)
    and (max-device-width: 667px)
    and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
  @media only screen
  /* ----------- iPhone 6+ ----------- */
  /* Portrait and Landscape */
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
    @content;
  }
  @media screen
  /* ----------- Galaxy S3 ----------- */
  /* Portrait and Landscape */
    and (device-width: 320px)
    and (device-height: 640px)
    and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
  @media screen
  /* ----------- HTC One ----------- */
  /* Portrait and Landscape */
    and (device-width: 360px)
    and (device-height: 640px)
    and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin retina2x {
  @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
    @content;
  }
}



// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.



// Okta colors
$primary-color: #007cc0;
$primary-border-color: #005380;
$primary-accent-color: #026ca4;
$primary-accent-border-color: #01486e;
$link-color: #0074b3;

$primary-medium-color: #46b3e9;
$primary-medium-border-color: #1b90c5;
$primary-light-color: #a9e6fd;

$secondary-color: #4cbf9c;
$secondary-color-text: #46b090;
$secondary-color-border: #338069;
$secondary-color-gradient-darker: #49b897;

$warning-color: #f1d10d;
$error-color: #e34843;
$teach-color: #835ab1;

// Text
$body-font-family: 'montserrat-okta', Arial, Helvetica, sans-serif;
$body-font-size: 13px;
$small-font-size: 12px;
$code-font-family: 'anonymous-pro', 'Andale Mono', 'Courier New', Courier, monospace;
$text-color-dark: #5e5e5e;
$text-color-light: #a7a7a7;
$text-color-darker: #444;
$text-color-placeholder: #a1a1a1;
$paragraph-line-height: 18px;
$component-heading-color: #919191;
$form-heading-color: $secondary-color-text;
$paragraph-margin: math.div($paragraph-line-height, 2);

// Buttons
$btn-bg-color-primary: $secondary-color;
$btn-bg-color-default: #fefefe;
$btn-bg-color-danger: #e24545;
$btn-bg-color-teach: $teach-color;

// Tabs
$tab-bg-color: transparent;
$tab-border-color: #dadada;
$tab-border-color-active: $secondary-color;

// Background colors
$component-bg-color: #f9f9f9;
$component-bg-color-light: #fbfbfb;
$component-bg-color-dark: #f2f2f2;
$item-count-bg-color: #cacaca;

// Borders
$border-color-default: #ddd;
$border-color-light: #eee;
$border-bottom-color-default: #dadada;
$border-default: 1px solid $border-color-default;
$border-radius-default: 3px;
$border-radius-top-left-default: 3px;
$border-radius-top-right-default: 3px;
$border-radius-bottom-left-default: 3px;
$border-radius-bottom-right-default: 3px;

// Box shadow
$box-shadow-default: 0 2px 0 rgba(175, 175, 175, 0.12);

// Common colors
$black: #000;
$white: #fff;
$red: #f00;


$fonts: Arial, Helvetica, sans-serif;
$default-border-radius: 3px;
$container-width: 400px;
$container-min-width: 300px;
$num-tabs: 5;

// Font Size
$font-size: 14px;
$font-size-small: 13px;
$font-size-header: 15px;
$font-size-footer: 12px;
$font-size-default: 15px;

// Font Weight
$font-weight-body: normal;
$font-weight-labels: 600;
$font-weight-header: 600;

// Theme Color
$primary-bg-color: #fff;
$secondary-bg-color: #f9f9f9;

// Text Color
$header-text-color: #5e5e5e;
$dark-text-color: #1d1d21;
$medium-text-color: #6e6e78;
$light-text-color: #6e6e78; // NOTE: light <> medium merged
$link-text-color: #0074b3;
$placeholder-text-color: #aaa;
$error-text-color: #e34843;

// Button Color
$icon-button-bg-color: #fff;
$button-bg-color: #fbfbfb;
$dark-button-bg-color: #929292;
$primary-button-bg-color: #007dc1;
$success-button-bg-color: #4cbf9c;
$negative-button-bg-color: #c35151;
$disabled-button-text-color: #aaa;
$disabled-primary-button-text-color: #fff;
$disabled-primary-button-bg-color: #63b1d7;
$disabled-primary-border-color: #3096c9;

// Divider Colors
$header-divider-color: #ddd;
$form-divider-color: #e8e8e8;

// Social Auth Button Colors
$other-icon-bg-color: #fff;
$other-label-bg-color: $button-bg-color;
$other-border-color: darken($button-bg-color, 22%);

// Default Custom Button Colors
$default-custom-button-background-color: #ff5f73;
$default-custom-button-border-color: #d6001a;

// IDP PIV Button Colors
$piv-button-background-color: #fff;
$piv-button-border-text-color: #1662dd;
$piv-action-button-background-color: #e5edfb;
$piv-action-button-border-text-color: #124a94;

// Button Size
$input-height: 40px;
$button-height: 50px;
$button-line-height: $input-height - 4px;
$button-padding: 0 15px;

// Input field
$input-bg-color: #fff;
$input-border-color: #8c8c96;
$input-border-color-hover: #1d1d21;
$input-border-color-focus: #0074b3;
$input-shadow-color-focus: #51cbee;
$input-icons-color: #a7a7a7;

// Modal
$modal-bg-color: #f9f9f9;

// Footer

// Other


@font-face {
  font-family: 'montserrat-okta';
  src: url('../font/montserrat-okta-light-webfont.eot');
  src: url('../font/montserrat-okta-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/montserrat-okta-light-webfont.woff') format('woff'),
    url('../font/montserrat-okta-light-webfont.ttf') format('truetype'),
    url('../font/montserrat-okta-light-webfont.svg#Montserrat-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-okta';
  src: url('../font/montserrat-okta-regular-webfont.eot');
  src: url('../font/montserrat-okta-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/montserrat-okta-regular-webfont.woff') format('woff'),
    url('../font/montserrat-okta-regular-webfont.ttf') format('truetype'),
    url('../font/montserrat-okta-regular-webfont.svg#Montserrat-SemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'okticon';
  src: url('../font/okticon.eot');
  src: url('../font/okticon.eot?#iefix') format('embedded-opentype'),
    url('../font/okticon.woff') format('woff'),
    url('../font/okticon.ttf') format('truetype'),
    url('../font/okticon.svg#okticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Web Font Loader classes
.wf-loading {
  visibility: hidden;

  // Fix for dashboard
  /* stylelint-disable declaration-no-important */
  .ajax-include-feedback-content {
    visibility: hidden !important;
  }
  /* stylelint-enable declaration-no-important */
}

.wf-active {
  visibility: visible;
}


.skip-to-content-link {
  font-family: "proxima nova", "montserrat-okta", Arial, Helvetica, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 10px;
  padding: 10px;
  margin-top: 10px;
  transform: translateY(-100%);
  transition: transform 0.3s;
  color: $primary-color;

  &:visited,
  &:hover,
  &:active {
    color: $primary-color;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    // show only on focus (happens via tab key)
    transform: translateY(0%);
  }
}

#okta-sign-in.auth-container {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // for IE to support main tag's width
    display: block;
  }

  &.main-container {
    /* -- Fonts and Text Colors -- */
    font-family: $fonts;
    color: $medium-text-color;
  }

  h2,
  h3 {
    /* -- Fonts and Text Colors -- */
    font-weight: $font-weight-header;
    color: $header-text-color;
  }

  .okta-sign-in-header {
    /* -- Fonts and Text Colors -- */
    color: $header-text-color;
  }

  .okta-form-subtitle {
    /* -- Fonts and Text Colors -- */
    color: $medium-text-color;
  }

  .okta-form-label {
    /* -- Fonts and Text Colors -- */
    font-weight: $font-weight-labels;
    color: $dark-text-color;
  }

  .o-form-explain {
    padding-top: 0;
  }

  .o-form-explain.okta-form-input-error {
    padding-top: 8px;
  }

  .link {
    /* -- Fonts and Text Colors: links -- */
    color: $medium-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $medium-text-color;
    }
  }

  .inline-link {
    /* -- Fonts and Text Colors: links -- */
    color: $link-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $link-text-color;
    }
  }

  input[type="submit"],
  input[type="button"] {
    /* -- Submit Buttons' Fonts -- */
    font-family: $fonts;

    &:focus {
      box-shadow: 0 0 8px $input-shadow-color-focus;
      border-color: $input-border-color-focus;
    }
  }


  &.main-container {
    /* -- Main Background and Border Colors -- */
    background-color: $primary-bg-color;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);

    @include mobile {
      border-width: 0;
      box-shadow: none;
    }

    .bg-helper {
      /* -- Main Background and Border Colors -- */
      /* set the helper's color value to the same color as the main-container's background */
      color: $primary-bg-color;
    }

    .primary-auth {
      // adjust password toggle on login page to accomodate for Tooltips
      .password-with-toggle {
        padding-right: 65px;
      }
    }
    // add divider to username/password on login when password toggle on
    .o-form .o-form-input .o-form-control {
      .input-icon-divider {
        border-right: 1px solid $input-border-color;
        height: 38px;
        position: absolute;
        right: 34px;
        top: 0;
      }

      &.focused-input {
        .input-icon-divider {
          border-right: 1px solid $input-border-color-focus;
        }
      }
    }

    .margin-btm-5 {
      margin-bottom: 5px;
    }

    .margin-btm-30 {
      margin-bottom: 30px;
    }

    .margin-top-30 {
      margin-top: 30px;
    }
  }

  .okta-sign-in-header {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: $header-divider-color;
  }

  .okta-sign-in-beacon-border {
    /* -- Main Background and Border Colors -- */
    border-color: #a7a7a7;
  }

  .okta-form-divider {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: $form-divider-color;
  }

  .okta-webauthn-authenticator {
    /* -- Webauthn Authenticator (small) -- */
    background-image: url('../img/icons/mfa/webauthn_authenticator.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Webauthn Authenticator Icons (high DPI) -- */
      background-image: url('../img/icons/mfa/webauthn_authenticator@2x.png');
    }
  }

  .okta-verify-authenticator {
    /* -- Okta Verify Authenticator (small) -- */
    background-image: url('../img/icons/mfa/oktaVerify_authenticator.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Okta Verify Authenticator Icons (high DPI) -- */
      background-image: url('../img/icons/mfa/oktaVerify_authenticator@2x.png');
    }
  }

  .factor-icon,
  .qrcode-image {
    /* -- Main Background and Border Colors -- */
    border-color: #ccc;
  }


  .mfa-okta-verify-30,
  .enroll-factor-row .mfa-okta-verify {
    /* -- Factor Icons (small): Okta Verify -- */
    background-image: url('../img/icons/mfa/oktaVerify_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('../img/icons/mfa/oktaVerify_ico@2x.png');
    }
  }

  .mfa-okta-verify {
    /* -- Factor Icons (large): Okta Verify -- */
    background-image: url('../img/icons/mfa/oktaVerify_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('../img/icons/mfa/oktaVerify@2x.png');
    }
  }

  .mfa-google-auth-30,
  .enroll-factor-row .mfa-google-auth {
    /* -- Factor Icons (small): Google Authenticator -- */
    background-image: url('../img/icons/mfa/googleAuth_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Google Authenticator -- */
      background-image: url('../img/icons/mfa/googleAuth_ico@2x.png');
    }
  }

  .mfa-google-auth {
    /* -- Factor Icons (large): Google Authenticator -- */
    background-image: url('../img/icons/mfa/googleAuth_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Google Authenticator  -- */
      background-image: url('../img/icons/mfa/googleAuth@2x.png');
    }
  }

  .mfa-hotp {
    /* -- Factor Icons (large): Hotp Factor -- */
    background-image: url('../img/icons/mfa/hotpFactor_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Hotp Factor  -- */
      background-image: url('../img/icons/mfa/hotpFactor@2x.png');
    }
  }

  .mfa-hotp-30 {
    /* -- Factor Icons (small): Hotp Factor -- */
    background-image: url('../img/icons/mfa/hotpFactor_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Hotp Factor  -- */
      background-image: url('../img/icons/mfa/hotpFactor_ico@2x.png');
    }
  }

  .mfa-symantec-30,
  .enroll-factor-row .mfa-symantec {
    /* -- Factor Icons (small): Symantec VIP -- */
    background-image: url('../img/icons/mfa/symantec_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Symantec VIP -- */
      background-image: url('../img/icons/mfa/symantec_ico@2x.png');
    }
  }

  .mfa-symantec {
    /* -- Factor Icons (large): Symantec VIP -- */
    background-image: url('../img/icons/mfa/symantec_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Symantec VIP -- */
      background-image: url('../img/icons/mfa/symantec@2x.png');
    }
  }

  .mfa-rsa-30,
  .enroll-factor-row .mfa-rsa {
    /* -- Factor Icons (small): RSA SecurID -- */
    background-image: url('../img/icons/mfa/rsa_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): RSA SecurID -- */
      background-image: url('../img/icons/mfa/rsa_ico@2x.png');
    }
  }

  .mfa-rsa {
    /* -- Factor Icons (large): RSA SecurID -- */
    background-image: url('../img/icons/mfa/rsa_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): RSA SecurID -- */
      background-image: url('../img/icons/mfa/rsa@2x.png');
    }
  }

  .mfa-onprem-30,
  .enroll-factor-row .mfa-onprem {
    /* -- Factor Icons (small): OnPrem -- */
    background-image: url('../img/icons/mfa/onprem_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): OnPrem -- */
      background-image: url('../img/icons/mfa/onprem_ico@2x.png');
    }
  }

  .mfa-onprem {
    /* -- Factor Icons (large): OnPrem -- */
    background-image: url('../img/icons/mfa/onprem_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): OnPrem -- */
      background-image: url('../img/icons/mfa/onprem@2x.png');
    }
  }

  .mfa-duo-30,
  .enroll-factor-row .mfa-duo {
    /* -- Factor Icons (small): DUO -- */
    background-image: url('../img/icons/mfa/duo_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): DUO -- */
      background-image: url('../img/icons/mfa/duo_ico@2x.png');
    }
  }

  .mfa-duo {
    /* -- Factor Icons (large): DUO -- */
    background-image: url('../img/icons/mfa/duo_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): DUO -- */
      background-image: url('../img/icons/mfa/duo@2x.png');
    }
  }

  .mfa-yubikey-30,
  .enroll-factor-row .mfa-yubikey {
    /* -- Factor Icons (small): Yubikey -- */
    background-image: url('../img/icons/mfa/yubico_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Yubikey -- */
      background-image: url('../img/icons/mfa/yubico_ico@2x.png');
    }
  }

  .mfa-yubikey {
    /* -- Factor Icons (large): Yubikey -- */
    background-image: url('../img/icons/mfa/yubico_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Yubikey -- */
      background-image: url('../img/icons/mfa/yubico@2x.png');
    }
  }

  .mfa-sms-30,
  .enroll-factor-row .mfa-okta-sms {
    /* -- Factor Icons (small): SMS -- */
    background-image: url('../img/icons/mfa/sms_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('../img/icons/mfa/sms_ico@2x.png');
    }
  }

  .mfa-okta-sms {
    /* -- Factor Icons (large): SMS -- */
    background-image: url('../img/icons/mfa/sms_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('../img/icons/mfa/sms@2x.png');
    }
  }

  .mfa-call-30,
  .enroll-factor-row .mfa-okta-call {
    /* -- Factor Icons (small): CALL -- */
    background-image: url('../img/icons/mfa/voicecall_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('../img/icons/mfa/voicecall_ico@2x.png');
    }
  }

  .mfa-okta-call {
    /* -- Factor Icons (large): CALL -- */
    background-image: url('../img/icons/mfa/voicecall_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('../img/icons/mfa/voicecall@2x.png');
    }
  }

  .mfa-okta-phone {
    /* -- Factor Icons (large): PHONE -- */
    background-image: url('../img/icons/mfa/phone_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): PHONE -- */
      background-image: url('../img/icons/mfa/phone@2x.png');
    }
  }

  .mfa-email-30,
  .enroll-factor-row .mfa-okta-email {
    /* -- Factor Icons (small): EMAIL -- */
    background-image: url('../img/icons/mfa/email_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('../img/icons/mfa/email_ico@2x.png');
    }
  }

  .mfa-okta-email {
    /* -- Factor Icons (large): EMAIL -- */
    background-image: url('../img/icons/mfa/email_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('../img/icons/mfa/email@2x.png');
    }
  }

  .mfa-question-30,
  .enroll-factor-row .mfa-okta-security-question {
    /* -- Factor Icons (small): Security Question -- */
    background-image: url('../img/icons/mfa/question_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('../img/icons/mfa/question_ico@2x.png');
    }
  }

  .mfa-okta-security-question {
    /* -- Factor Icons (large): Security Question -- */
    background-image: url('../img/icons/mfa/question_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('../img/icons/mfa/question@2x.png');
    }
  }

  .mfa-password-30 {
    /* -- Factor Icons (small): Password -- */
    background-image: url('../img/icons/mfa/password_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Password -- */
      background-image: url('../img/icons/mfa/password_ico@2x.png');
    }
  }

  .mfa-windows-hello-30,
  .enroll-factor-row .mfa-windows-hello {
    /* -- Factor Icons (small): Windows Hello -- */
    background-image: url('../img/icons/mfa/windowsHello_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Windows Hello -- */
      background-image: url('../img/icons/mfa/windowsHello_ico@2x.png');
    }
  }

  .mfa-windows-hello {
    /* -- Factor Icons (large): Windows Hello -- */
    background-image: url('../img/icons/mfa/windowsHello_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Windows Hello -- */
      background-image: url('../img/icons/mfa/windowsHello@2x.png');
    }
  }

  .mfa-u2f-30,
  .enroll-factor-row .mfa-u2f {
    /* -- Factor Icons (small): U2F -- */
    background-image: url('../img/icons/mfa/u2f_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): U2F -- */
      background-image: url('../img/icons/mfa/u2f_ico@2x.png');
    }
  }

  .mfa-u2f {
    /* -- Factor Icons (large): U2F -- */
    background-image: url('../img/icons/mfa/u2f_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): U2F -- */
      background-image: url('../img/icons/mfa/u2f@2x.png');
    }
  }

  .mfa-okta-password {
    /* -- Factor Icons (large): Password -- */
    background-image: url('../img/icons/mfa/password_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Password -- */
      background-image: url('../img/icons/mfa/password@2x.png');
    }
  }

  .mfa-custom-factor-30,
  .enroll-factor-row .mfa-custom-factor {
    /* -- Factor Icons (small): Custom Factor -- */
    background-image: url('../img/icons/mfa/customFactor_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Custom Factor -- */
      background-image: url('../img/icons/mfa/customFactor_ico@2x.png');
    }
  }

  .mfa-custom-factor {
    /* -- Factor Icons (large): Custom Factor -- */
    background-image: url('../img/icons/mfa/customFactor_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Custom Factor -- */
      background-image: url('../img/icons/mfa/customFactor@2x.png');
    }
  }

  .mfa-webauthn-30,
  .enroll-factor-row .mfa-webauthn {
    /* -- Factor Icons (small): Webauthn -- */
    background-image: url('../img/icons/mfa/webauthn_38x38.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/webauthn_ico@2x.png');
    }
  }

  .mfa-webauthn {
    /* -- Factor Icons (large): Webauthn -- */
    background-image: url('../img/icons/mfa/webauthn_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/webauthn@2x.png');
    }
  }

  .mfa-custom-app-logo {
    background-image: url('../img/icons/mfa/custom-app-default-logo.svg');
    background-size: 70%;
    &.custom-logo {
      background-size: contain;
    }
  }

  .icon--app {
    /* -- Factor Icons (large): Webauthn -- */
    background-image: url('../img/icons/mfa/app_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/app_76x76.png');
    }
  }

  .icon--desktop {
    /* -- Factor Icons (large): Webauthn -- */
    background-image: url('../img/icons/mfa/desktop_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/desktop_76x76.png');
    }
  }

  .icon--smartphone {
    /* -- Factor Icons (large): Webauthn -- */
    background-image: url('../img/icons/mfa/smartphone_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/smartphone_76x76.png');
    }
  }

  .icon--location {
    /* -- Factor Icons (large): Webauthn -- */
    background-image: url('../img/icons/mfa/location_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Webauthn -- */
      background-image: url('../img/icons/mfa/location_76x76.png');
    }
  }

  .smartcard,
  .mfa-smartcard {
    /* -- Factor Icons (large): PIV -- */
    background-image: url('../img/icons/login/smartcard_70x70.png');

    @media
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): PIV -- */
      background-image: url('../img/icons/login/smartcard@2x.png');
    }
  }

  .button {
    /* -- Buttons' Colors -- */
    @include light-button-template($button-bg-color);

    &:focus {
      border-color: $input-border-color-focus;
    }
  }

  .button-dark {
    /* -- Buttons' Colors -- */
    @include dark-button-template($dark-button-bg-color);
  }

  .button-primary {
    /* -- Buttons' Colors -- */
    @include dark-button-template($primary-button-bg-color);
  }

  .button-success {
    /* -- Buttons' Colors -- */
    @include dark-button-template($success-button-bg-color);
  }

  .button.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: $disabled-button-text-color;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  .button.button-primary.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: $disabled-primary-button-text-color;
    background-color: $disabled-primary-button-bg-color;
    border-color: $disabled-primary-button-bg-color;
    background-image: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  .icon-button {
    /* -- Buttons' Colors -- */
    @include button-template($icon-button-bg-color, 0%, $dark-text-color, 23%, 26%);
  }


  .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    /* background: #fff; */
    border-color: $input-border-color-hover;
  }

  .factors-dropdown-wrap .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .okta-dropdown-option {
    /* -- Dropdown Colors -- */
    /* background: transparent; */
    /* border-bottom-color: #fff; */

    &:hover {
      /* -- Dropdown Colors -- */
      /* background: #f9f9f9; */
    }

    &.dropdown-list-title:hover {
      /* -- Dropdown Colors -- */
      background: transparent;
    }

    a {
      /* -- Dropdown Colors -- */
      color: $dark-text-color;
    }
  }


  .okta-infobox-error {
    /* -- Error Infobox Colors -- */
    /* background-color: #fff; */
    /* border: 1px solid #ddd; */

    &:before {
      /* -- Error Infobox Colors -- */
      /* background-color: #e34843; */
    }

    .error-16:before {
      /* -- Error Infobox Colors: error icon -- */
      /* color: #fff; */

      /* Use the rules below to replace an error icon in error infoboxes */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }

  }

  .enroll-required-factor-list {
    .enroll-factor-row-min .enroll-factor-description {
      h3 {
        /* -- Enroll required factors, factors that are not the current step -- */
        color: $light-text-color;
      }
    }

  }

  .okta-form-input-field {
    /* -- Input Fields -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;

    &:hover {
      /* -- Input Fields -- */
      border-color: $input-border-color-hover;
    }

    &.focused-input {
      border-color: $input-border-color-focus;
    }

    input {
      /* -- Input Fields -- */
      font-family: $fonts;
      color: $dark-text-color;

      &::-webkit-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &::-moz-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &:-ms-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &[disabled] {
        /* -- Input Fields: disabled input field -- */
        color: $medium-text-color;
        -webkit-text-fill-color: $medium-text-color;
        /* background: #f4f4f4; */
      }

      &[type="password"] {
        /* -- Input Fields: password input field -- */
        font-family: Verdana, sans-serif;

        &:placeholder-shown {
          font-family: $fonts;
        }
      }

    }

    placeholder {
      /* -- Input Fields: placeholder -- */
      font-family: $fonts;
      color: $placeholder-text-color;
    }
  }

  .input-icon:before,
  .input-tooltip:before {
    /* -- Input Fields: icons -- */
    color: $input-icons-color;
  }

  .okta-form-label-inline {
    /* -- Input Fields -- */
    /* background-color: #f2f2f2; */
    /* border-color: #c8c8c8; */
    /* color: #999; */
  }

  .okta-form-input-error {
    /* -- Input Fields: error highlight -- */
    /* color: #d93934; */

    .error-16-small:before {
      /* -- Input Fields: error icon -- */
      /* color: #e34843; */

      /* Use the rules below to replace input fields error icon */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }
  }


  .chzn-single {
    /* -- Input Fields: Select Input -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;

    span {
      /* -- Input Fields: Select Input -- */
      color: $medium-text-color;
    }

    &:hover {
      /* -- Input Fields: Select Input -- */
      border-color: $input-border-color-hover;
    }
  }

  .chzn-with-drop {
    .chzn-single,
    .chzn-drop {
      /* -- Input Fields: Select Input -- */
      background-color: $input-bg-color;
      border-color: $input-border-color-hover;
    }

    .chzn-results {

      li {
        /* -- Input Fields: Select Input -- */
        /* border-color: #fff; */
      }

      .highlighted {
        /* -- Input Fields: Select Input -- */
        /* background-color: #f9f9f9; */
      }
    }
  }

  .primary-auth { /* -- Page specific top level selectors -- */ }
  .password-expired { /* -- Page specific top level selectors -- */ }
  .password-reset { /* -- Page specific top level selectors -- */ }
  .password-reset-email-sent { /* -- Page specific top level selectors -- */ }
  .forgot-password { /* -- Page specific top level selectors -- */ }
  .account-unlock { /* -- Page specific top level selectors -- */ }
  .account-unlock-email-sent { /* -- Page specific top level selectors -- */ }
  .recovery-challenge { /* -- Page specific top level selectors -- */ }
  .recovery-loading { /* -- Page specific top level selectors -- */ }
  .recovery-question { /* -- Page specific top level selectors -- */ }
  .refresh-auth-state { /* -- Page specific top level selectors -- */ }

  .enroll-choices { /* -- Page specific top level selectors -- */ }
  .enroll-totp { /* -- Page specific top level selectors -- */ }
  .barcode-totp { /* -- Page specific top level selectors -- */ }
  .activate-totp { /* -- Page specific top level selectors -- */ }
  .enroll-manual-totp { /* -- Page specific top level selectors -- */ }
  .barcode-push { /* -- Page specific top level selectors -- */ }
  .activate-push { /* -- Page specific top level selectors -- */ }
  .enroll-manual-push { /* -- Page specific top level selectors -- */ }
  .enroll-activation-link-sent { /* -- Page specific top level selectors -- */ }
  .enroll-symantec { /* -- Page specific top level selectors -- */ }
  .enroll-rsa { /* -- Page specific top level selectors -- */ }
  .enroll-onprem { /* -- Page specific top level selectors -- */ }
  .enroll-duo { /* -- Page specific top level selectors -- */ }
  .enroll-sms {
    /* -- Page specific top level selectors -- */
    .infobox {
      b {
        font-weight: bold;
      }
    }
  }
  .enroll-call {
    /* -- Page specific top level selectors -- */
    .infobox {
      b {
        font-weight: bold;
      }
    }
  }
  .enroll-question { /* -- Page specific top level selectors -- */ }

  .mfa-verify-duo { /* -- Page specific top level selectors -- */ }
  .mfa-verify { /* -- Page specific top level selectors: all challenge pages except duo -- */ }
  .mfa-verify-totp { /* -- Page specific top level selectors -- */ }
  .mfa-verify-push { /* -- Page specific top level selectors -- */ }
  .mfa-verify-totp-inline { /* -- Page specific top level selectors -- */ }
  .mfa-verify-question { /* -- Page specific top level selectors -- */ }
  .mfa-verify-passcode {
    /* -- Page specific top level selectors -- */
    .infobox {
      b {
        font-weight: bold;
      }
    }
  }
  .mfa-verify-password { /* -- Page specific top level selectors -- */ }

}


.okta-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $fonts;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
    /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }

  .qtip-titlebar {
    /* -- Tooltips -- */
    /* background-color: #404040 */
  }
}

.okta-sign-in-security-image-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $fonts;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
    /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }
}

/*  Remove the background image and borders on a smaller window size */
@include max-width-mq(600px) {
  /* stylelint-disable declaration-no-important */
  .login-bg-image {
    background-image: none !important;
    background-color: #fff !important;
    filter: unset !important;
  }
  /* stylelint-enable declaration-no-important */

  /* Using the same selector as line 59 to override styles at small screen sizes. */
  #okta-sign-in.auth-container {

    width: auto;
    margin-right: 0;
    margin-left: 0;

    &.main-container {
      border: 0;
      box-shadow: none;
    }

    .auth-content {
      max-width: $container-width - 84px;
      margin: 0 auto;
    }

  }
}
