/*
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */

 body {
  font-family:  Arial, Helvetica, sans-serif;
}
.heading {
  font-family: 'Century Gothic W01', 'Muli', Arial, Helvetica, sans-serif;
}
.copy {
  font-family:  Arial, Helvetica, sans-serif;
}

$small-break: 767px;
$iphone5-break: 321px;
$iphone6-break: 428px;

/*colors*/
$brand-primary:         #49176D;
$primary: $brand-primary;
$brand_primary-darker: darken($brand-primary, 5%);
$brand_primary-darkest: darken($brand-primary, 10%);
$brand_primary-lighter: lighten($brand-primary, 4%);
$brand_primary-lightest: lighten($brand_primary-lighter, 6%) !default;
$text-color-primary:    #FFF;
$brand-success:         #5cb85c !default;
$success: $brand-success;
$brand-info:            #5bc0de !default;
$info: $brand-info;
$brand-warning:         #f0ad4e !default;
$warning: $brand-warning;
$brand-danger:          #d9534f !default;
$danger: $brand-danger;
$panel-background:      #F2F2F2;

$body-bg: whitesmoke;

$link-color:            $brand-primary !default;
$link-hover-color:      darken($brand-primary, 15%) !default;
$link-hover-decoration: underline !default;

/*  buttons */
$btn-primary-color:     $brand-primary !default;
$btn-primary-bg:        #ffffff !default;
$btn-primary-border:    darken($brand-primary, 5%) !default;

$btn-default-color:     $brand-primary !default;
$btn-default-bg:        #ffffff !default;
$btn-default-border:    darken($brand-primary, 5%) !default;
$btn-font-weight:       300 !default;

$component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

$light-border: lighten(#D3D3D3, 10%);


$font-size-base:            0.85rem;
$font-size-h1:              floor(($font-size-base * 3));
$headings-line-height:      1;
$text-color:                #333;
$row-highlight:             lighten(lightgrey, 12%);
