@import "../../../node_modules/bootstrap/scss/bootstrap";


@import "~bootstrap/scss/_functions.scss";
@import "./variables.scss";
@import "./features/app.scss";
@import "./features/bv360.scss";
@import "./features/form.scss";
@import "./features/login.scss";



#content {
    width: 100%;
    padding-top: 20px;
}

.btn-primary {
    color: #fff;
    background-color: #49176D;
    border-color: #49176D;
}

.btn-primary:hover {
    background-color: #621e93;
    border-color: #621e93;
}

.has-error {
    color: #d9534f;
}


.container.page-content {
    height: 100%;
    width: 100%;
    max-width: 100%;
}

.brand-header {
    max-width: 100%;

}

.container.brand-header {
    margin-left: 0;
    padding-left: 0;
}

.radio-group {
    height: 34px;
    padding: 6px 12px;
  }

// .radio-inline,
// .checkbox-inline {
//   position: relative;
//   display: inline-block;
//   padding-left: 20px;
//   margin-bottom: 0;
//   font-weight: 400;
//   vertical-align: middle;
//   cursor: pointer;

// }

// .radio input[type="radio"],
// .radio-inline input[type="radio"],
// .checkbox input[type="checkbox"],
// .checkbox-inline input[type="checkbox"] {
//   position: absolute;
//   margin-top: 4px \9;
//   margin-left: -20px;
// }

// .radio-inline + .radio-inline,
// .checkbox-inline + .checkbox-inline {
//   margin-top: 0;
//   margin-left: 10px; // space out consecutive inline controls
// }

.card {
    font-size: 0.75rem;
}

.form-control {
    font-size: 0.75rem;
}

label {
    padding-bottom: 0.5em;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
    width: inherit;
}

.radio-inline {
    margin-left: 2em;
}

.react-datepicker {
    font-size: 1em;
  }
.react-datepicker__header {
padding-top: 0.8em;
}
.react-datepicker__month {
margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
width: 1.9em;
line-height: 1.9em;
margin: 0.166em;
}
.react-datepicker__current-month {
font-size: 1em;
}
.react-datepicker__navigation {
top: 1em;
line-height: 1.7em;
border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
border-right-color: #ccc;
left: 1em;
}
.react-datepicker__navigation--next {
border-left-color: #ccc;
right: 1em;
}

.surgical-datepicker-width .react-datepicker-wrapper  {
    width: 95%;
}

.surgical-datepicker-width .surgical-datepicker-width {
    width: 95%;
}

.new-or-existing-field .Select-menu-outer {
    width: 25em;
}

.open-orders-table .table-responsive {
    overflow: auto
}

.new-orders-table .table-responsive {
    overflow: visible
}

.surgical-form-card .row {
    margin-left: 20px;
    margin-right: 20px;
}

.text-wrap { white-space: normal; }

.permissions-db-modal {
    margin-top: 10%;
}

.permissions-db-modal.fade {
    opacity: 1;
}

.surgical-open-order-po-modal.fade {
    opacity: 1;
}

.open-orders-download-csv-button {
     margin-right: auto;
     margin-left: 2em;
     max-width: 0.75rem;
}

.open-orders-download-csv-button:visited {
    color: white
}

.inventory-download-csv-button {
    margin-right: auto;
    margin-left: 2em;

}

.inventory-download-csv-button:visited {
   color: white
}

.inventory-orders-table .table-responsive {
    overflow: auto
}

.surgical-table-spinner {
    position: relative;
    margin-left: 48%;
    margin-top: 7em;
    color: #49176D;
}

// .open-orders-download-csv-button {
//     margin-right: auto;
//     margin-left: 2em;
// }
