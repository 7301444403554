form {
  width: 100%;
}

.card {
  margin-bottom: 1em;
}

.card-header {
  font-weight: 600;
  font-size: larger;
}

.card-body > .form-row > div {
  margin-bottom: 0.7em;
}

.has-error {
  color: #d9534f;
}

.has-success {
  color: $brand-success;
}

.has-warning {
  color: $brand-warning;
}

.has-success {
  color: $brand-success;
}

.has-warning {
  color: $brand-warning;
}

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.is-valid input,
.is-valid > .Select-control > .Select-multi-value-wrapper {
  border-color: #5cb85c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75rem + 0.375rem) calc(0.75rem + 0.375rem);
}

.is-valid > .Select-control {
  border-color: #5cb85c;
}

.is-disabled {
  .Select-control {
    .Select-multi-value-wrapper {
      background-color: #e9ecef !important;
      opacity: 1;
    }
  }
}

.is-disabled {
  .Select-control {
    .Select-multi-value-wrapper {
      background-color: #e9ecef !important;
      opacity: 1;
    }
  }
}

.option--custom {
  padding: 5px;
  border-bottom: 1px solid $light-border;
  border-top: 1px solid $light-border;
  cursor: pointer;
  font-size: 0.7rem;
}
